'use client';

import { useTranslations } from 'next-intl';
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';

import AccessibleIcon from '@icons/accessible.svg';
import ContrastIcon from '@icons/contrast.svg';
import FontSizeIcon from '@icons/font-size.svg';
import EasyLanguageIcon from '@icons/easy-language.svg';

const SETTINGS_KEY = 'accessibility-settings';

const initialData = {
  fontSize: 1,
  highContrast: false,
};

type Settings = typeof initialData;

type Props = {
  easyLanguageLink: string;
  accessibilityPageLink: string;
};

function AccessibilityNavigation(props: Props) {
  const { easyLanguageLink, accessibilityPageLink } = props;
  const [settings, setSettings] = useState<Settings>(initialData);
  const initialRef = useRef(true);
  const t = useTranslations('Navigation');

  useEffect(() => {
    const data = localStorage.getItem(SETTINGS_KEY);
    if (data) {
      setSettings(JSON.parse(data) as Settings);
    }
  }, []);

  useEffect(() => {
    // toggle high-contrast css-class on html element
    document.documentElement.classList.toggle('high-contrast', settings.highContrast);
  }, [settings.highContrast]);

  useEffect(() => {
    // toggle font-size css-class on html element
    document.documentElement.classList.toggle('font-smaller', settings.fontSize === 0);
    document.documentElement.classList.toggle('font-bigger', settings.fontSize === 2);
  }, [settings.fontSize]);

  useEffect(() => {
    if (!initialRef.current) {
      // Update settings on change
      localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
    }
    initialRef.current = false;
  }, [settings]);

  return (
    <div className='accessibility-settings'>
      <button
        className='accessibility-settings__button'
        onClick={() => setSettings(prev => ({ ...prev, fontSize: (prev.fontSize + 1) % 3 }))}
      >
        {t('Schriftgröße anpassen')}
        <FontSizeIcon width='1.5em' height='1.5em' />
      </button>
      <button
        className='accessibility-settings__button'
        onClick={() => setSettings(prev => ({ ...prev, highContrast: !prev.highContrast }))}
      >
        {settings.highContrast ? t('Kontrast zurücksetzen') : t('Kontrast erhöhen')}
        <ContrastIcon width='1.5em' height='1.5em' />
      </button>
      <Link href={easyLanguageLink} className='accessibility-settings__link'>
        {t('Leichte Sprache')}
        <EasyLanguageIcon width='1.5em' height='1.5em' />
      </Link>
      <Link href={accessibilityPageLink} className='accessibility-settings__link'>
        {t('Barrierefreier Besuch')}
        <AccessibleIcon width='1.5em' height='1.5em' />
      </Link>
    </div>
  );
}

export default AccessibilityNavigation;
