import Link from 'next/link';
import React from 'react';

import DeIcon from '@icons/de.svg';
import EnIcon from '@icons/en.svg';
import NlIcon from '@icons/nl.svg';
import { useTranslations } from 'next-intl';
import { PageData, PageMetaDataList, WagtailPage } from '@/types';
import { removeBaseUrl } from '@utils/url';

type Props = {
  translations?: PageMetaDataList;
  pageData?: PageData;
};

function LocaleNavigation(props: Props) {
  const { translations, pageData } = props;
  const t = useTranslations('LocaleSelection');

  let deLink = translations?.items.find(translation => translation.meta.locale === 'de')?.meta
    .html_url;
  let enLink = translations?.items.find(translation => translation.meta.locale === 'en')?.meta
    .html_url;
  let nlLink = translations?.items.find(translation => translation.meta.locale === 'nl')?.meta
    .html_url;

  if (pageData?.meta?.locale === 'de') deLink = pageData.meta.html_url;
  if (pageData?.meta?.locale === 'en') enLink = pageData.meta.html_url;
  if (pageData?.meta?.locale === 'nl') nlLink = pageData.meta.html_url;

  return (
    <nav id='locale-menu' aria-label='Sprachmenü' className='locale-navigation'>
      <h2 className='locale-navigation__title'>{t('Sprache')}</h2>
      <ul className='locale-navigation__list'>
        <li className='locale-navigation__list-item'>
          <Link
            href={deLink ? removeBaseUrl(deLink) : '/de'}
            data-language='de'
            className='locale-navigation__link'
            hrefLang={deLink ? 'de' : undefined}
          >
            {t('Deutsch')} <DeIcon width={33} height={32} />
          </Link>
        </li>
        <li className='locale-navigation__list-item'>
          <Link
            href={enLink ? removeBaseUrl(enLink) : '/en'}
            data-language='en'
            className='locale-navigation__link'
            hrefLang={deLink ? 'en' : undefined}
          >
            {t('Englisch')} <EnIcon width={33} height={32} />
          </Link>
        </li>
        <li className='locale-navigation__list-item'>
          <Link
            href={nlLink ? removeBaseUrl(nlLink) : '/nl'}
            data-language='nl'
            className='locale-navigation__link'
            hrefLang={deLink ? 'nl' : undefined}
          >
            {t('Niederländisch')} <NlIcon width={33} height={32} />
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default LocaleNavigation;
