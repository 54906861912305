import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/neanderthal_website/frontend/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/neanderthal_website/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/neanderthal_website/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/neanderthal_website/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/neanderthal_website/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/neanderthal_website/frontend/src/components/Dialog/Dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/neanderthal_website/frontend/src/components/EventRegistrationForm/EventRegistrationForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/neanderthal_website/frontend/src/components/Navigation/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/neanderthal_website/frontend/src/components/PrintButton/PrintButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/neanderthal_website/frontend/src/components/WorkshopRegistrationForm/WorkshopRegistrationForm.tsx");
;
import(/* webpackMode: "eager" */ "/opt/neanderthal_website/frontend/src/icons/clock.svg");
;
import(/* webpackMode: "eager" */ "/opt/neanderthal_website/frontend/src/icons/map_marker.svg");
;
import(/* webpackMode: "eager" */ "/opt/neanderthal_website/frontend/src/icons/tickets.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/neanderthal_website/frontend/src/streamfields/Accordions/Accordions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/neanderthal_website/frontend/src/streamfields/GeneralEvents/GeneralEvents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/neanderthal_website/frontend/src/streamfields/SchoolEvents/SchoolEvents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/neanderthal_website/frontend/src/views/FormPage/FormPage.tsx");
