import React, { Suspense } from 'react';
import type { Props } from './NavigationAccordion';
import Loading from './Loading';

const NavigationAccordion = React.lazy(() => import('./NavigationAccordion'));

function SuspendedTickets(props: Props) {
  return (
    <Suspense fallback={<Loading {...props} />}>
      <NavigationAccordion {...props} />
    </Suspense>
  );
}

export default SuspendedTickets;
