import { Navbar } from '@/types';
import { useTranslations } from 'next-intl';
import NavigationAccordion from '../NavigationAccordion';

type Props = { data?: Navbar };

function MainNavigation(props: Props) {
  const { data } = props;
  const t = useTranslations('Navigation');

  if (!data) return null;

  return (
    <nav id='primary-menu' className='main-navigation' aria-label={t('Hauptnavigation')}>
      <NavigationAccordion items={data?.menu_items ?? []} />
    </nav>
  );
}

export default MainNavigation;
