import {
  AnchorLink,
  DocumentMenuLink,
  ExternalMenuLink,
  FooterText,
  InternalMenuLink,
  LinkWithIcon,
  SocialLinks,
  WagtailPage,
} from '@/types';

function isInternalLink(item: any): item is InternalMenuLink {
  return item?.type === 'page_link';
}

function isExternalLink(item: any): item is ExternalMenuLink {
  return item?.type === 'external_link';
}

function isDocumentLink(item: any): item is DocumentMenuLink {
  return item?.type === 'document_link';
}

function isLinkWithIcon(item: any): item is LinkWithIcon {
  return item?.type === 'link_with_icon';
}

function isWagtailPage(item: any): item is WagtailPage {
  return Boolean(item?.full_url);
}

function isText(item: any): item is FooterText {
  return item?.type === 'text';
}

function isSocialLinks(item: any): item is SocialLinks {
  return item?.type === 'social_links';
}

function isAnchorLink(item: any): item is AnchorLink {
  return item?.type === 'anchor_link';
}

export {
  isAnchorLink,
  isDocumentLink,
  isExternalLink,
  isInternalLink,
  isLinkWithIcon,
  isSocialLinks,
  isText,
  isWagtailPage,
};
