'use client';

import { usePathname } from 'next/navigation';
import React, { HTMLAttributes, useEffect, useRef } from 'react';

type Props = { 'aria-controls': string } & Omit<
  HTMLAttributes<HTMLButtonElement>,
  'onClick' | 'type' | 'aria-haspopup'
>;

function Hamburger(props: Props) {
  const pathname = usePathname();
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    ref.current?.removeAttribute('aria-expanded');
  }, [pathname]);

  return (
    <button
      className='hamburger'
      {...props}
      ref={ref}
      type='button'
      aria-haspopup='menu'
      aria-label='Menü öffnen/schließen'
      onClick={e => {
        const expanded = e.currentTarget.getAttribute('aria-expanded');
        if (expanded) {
          e.currentTarget.removeAttribute('aria-expanded');
        } else {
          e.currentTarget.setAttribute('aria-expanded', 'true');
        }
      }}
    >
      <span />
    </button>
  );
}

export default Hamburger;
