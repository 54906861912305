import { MenuItem } from '@/types';
import Debug from '@components/Debug';
import {
  isAnchorLink,
  isDocumentLink,
  isExternalLink,
  isInternalLink,
  isWagtailPage,
} from '@utils/navigation';
import { addBaseUrl, removeBaseUrl } from '@utils/url';
import Link from 'next/link';

type Props = {
  baseClassName: string;
  hideSubLinks?: boolean;
  items: MenuItem[];
};

function NavigationList(props: Props) {
  const { hideSubLinks, items, baseClassName } = props;

  return (
    <ul className={`${baseClassName}__list`}>
      {items?.map(item => {
        if (isInternalLink(item)) {
          if (!item.value.page) return null;
          return (
            <li key={item.id} className={`${baseClassName}__list-item`}>
              <Link
                href={removeBaseUrl(item.value.page.full_url)}
                className={`${baseClassName}__link`}
              >
                {item.value.display_text ? item.value.display_text : item.value.page.title}
              </Link>
              {!hideSubLinks && item?.value.sub_links?.length ? (
                <NavigationList items={item.value.sub_links} baseClassName={baseClassName} />
              ) : null}
            </li>
          );
        }

        if (isAnchorLink(item)) {
          return (
            <li key={item.id} className={`${baseClassName}__list-item`}>
              <Link
                href={`${removeBaseUrl(item.value.page.full_url)}#${item.value.anchor}`}
                className={`${baseClassName}__link`}
              >
                {item.value.display_text}
              </Link>
            </li>
          );
        }

        if (isExternalLink(item)) {
          return (
            <li key={item.id} className={`${baseClassName}__list-item`}>
              <Link
                href={item.value.link}
                target='_blank'
                rel='noreferrer noopener'
                className={`${baseClassName}__link`}
              >
                {item.value.display_text ? item.value.display_text : item.value.link}
              </Link>
              {!hideSubLinks && item?.value.sub_links?.length ? (
                <NavigationList items={item.value.sub_links} baseClassName={baseClassName} />
              ) : null}
            </li>
          );
        }

        if (isDocumentLink(item)) {
          return (
            <li key={item.id} className={`${baseClassName}__list-item`}>
              <Link
                href={addBaseUrl(item.value.document.file)}
                className={`${baseClassName}__link`}
              >
                {item.value.display_text ? item.value.display_text : item.value.document.title}
              </Link>
              {!hideSubLinks && item?.value.sub_links?.length ? (
                <NavigationList items={item.value.sub_links} baseClassName={baseClassName} />
              ) : null}
            </li>
          );
        }

        if (isWagtailPage(item)) {
          return (
            <li key={item.id} className={`${baseClassName}__list-item`}>
              <Link href={removeBaseUrl(item.full_url)} className={`${baseClassName}__link`}>
                {item.title}
              </Link>
            </li>
          );
        }

        return null;
      })}
    </ul>
  );
}

export default NavigationList;
