import { Navbar } from '@/types';
import clsx from 'clsx';
import NavigationList from '../NavigationList';

type Props = { data?: Navbar };

function SubNavigation(props: Props) {
  const { data } = props;

  if (!data) return null;

  return (
    <div className={clsx('sub-navigation', data.custom_css_class)}>
      {data.display_title ? <p className='sub-navigation__title'>{data.name}</p> : null}
      <NavigationList items={data?.menu_items ?? []} hideSubLinks baseClassName='sub-navigation' />
    </div>
  );
}

export default SubNavigation;
