/* eslint-disable react/no-array-index-key */
import { Props } from './NavigationAccordion';
import NavigationList from '../NavigationList';

function Loading(props: Props) {
  const { items } = props;
  return <NavigationList items={items ?? []} baseClassName='main-navigation' />;
}

export default Loading;
