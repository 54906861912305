'use client';

/* eslint-disable react/no-danger */
import React from 'react';
import Link from 'next/link';

import TicketsIcon from '@icons/tickets.svg';
import ShopIcon from '@icons/shop.svg';
import GroupIcon from '@icons/group.svg';

import { useTranslations } from 'next-intl';

function TicketShopNavigation() {
  const t = useTranslations('Navigation');

  return (
    <div className='ticket-shop-navigation'>
      <Link
        className='ticket-shop-navigation__link ticket-shop-navigation__link--shop'
        href={t('E-Shop-URL')}
        target='_blank'
        rel='noreferrer noopener'
      >
        <TicketsIcon width={60} height={60} />
        <span
          className='column'
          dangerouslySetInnerHTML={{
            __html: t.raw('<strong>Tickets</strong> <small>kaufen</small>'),
          }}
        />
      </Link>
      <Link
        className='ticket-shop-navigation__link ticket-shop-navigation__link--tours'
        href='https://e-shop.neanderthal.de/de/guided-tours'
        target='_blank'
        rel='noreferrer noopener'
      >
        <GroupIcon width={45} height={45} />
        <span
          className='column column--centered'
          dangerouslySetInnerHTML={{
            __html: t.raw('<strong>Veranstaltungen</strong> <small>buchen</small>'),
          }}
        />
      </Link>
      <Link
        className='ticket-shop-navigation__link ticket-shop-navigation__link--merchandise'
        href='https://e-shop.neanderthal.de/de/merchandise'
        target='_blank'
        rel='noreferrer noopener'
      >
        <ShopIcon width={45} height={45} />
        <strong>{t('Museumsshop')}</strong>
      </Link>
    </div>
  );
}

export default TicketShopNavigation;
