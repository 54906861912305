'use client';

import Hamburger from '@components/Hamburger';

import * as Tabs from '@radix-ui/react-tabs';
import LocaleNavigation from './LocaleNavigation';

import AccessibilityIcon from '@icons/accessibility.svg';
import GlobeIcon from '@icons/globe.svg';
import MenuIcon from '@icons/menu.svg';
import SearchIcon from '@icons/search.svg';
import TicketsIcon from '@icons/tickets.svg';

import { PageData, PageMetaDataList, WagtailNavigation } from '@/types';
import SearchForm from '@components/SearchForm';
import { removeBaseUrl } from '@utils/url';
import { useTranslations } from 'next-intl';
import AccessibilityNavigation from './AccessibilityNavigation';
import MainNavigation from './MainNavigation';
import SubNavigation from './SubNavigation';
import TicketShopNavigation from './TicketShopNavigation';

type Props = {
  data?: WagtailNavigation;
  translations?: PageMetaDataList;
  pageData?: PageData;
};

function Navigation(props: Props) {
  const { data, translations, pageData } = props;
  const t = useTranslations('Navigation');

  return (
    <>
      <Hamburger aria-controls='navigation-menu' />
      <div className='navigation' id='navigation-menu' role='menu'>
        <section className='main-navigation'>
          <Tabs.Root className='navigation-tabs' defaultValue='menu'>
            <Tabs.List className='navigation-tabs__list' aria-label={t('Seitennavigation')}>
              <Tabs.Trigger className='navigation-tabs__trigger' value='shop'>
                <TicketsIcon aria-label={t('Online Shop')} width={25} height={25} />
              </Tabs.Trigger>
              <Tabs.Trigger className='navigation-tabs__trigger' value='accessibility'>
                <AccessibilityIcon aria-label={t('Barrierefreiheit')} width={25} height={25} />
              </Tabs.Trigger>
              <Tabs.Trigger className='navigation-tabs__trigger' value='search'>
                <SearchIcon aria-label={t('Suche')} width={25} height={25} />
              </Tabs.Trigger>
              <Tabs.Trigger className='navigation-tabs__trigger' value='locales'>
                <GlobeIcon aria-label={t('Sprachauswahl')} width={25} height={25} />
              </Tabs.Trigger>
              <Tabs.Trigger className='navigation-tabs__trigger' value='menu'>
                <MenuIcon aria-label={t('Hauptnavigation')} width={37} height={25} />
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value='menu' className='navigation-tabs__content' forceMount>
              <MainNavigation data={data?.main_navigation} />
            </Tabs.Content>
            <Tabs.Content value='locales' className='navigation-tabs__content' forceMount>
              <LocaleNavigation pageData={pageData} translations={translations} />
            </Tabs.Content>
            <Tabs.Content value='search' className='navigation-tabs__content' forceMount>
              <h2 className='navigation__title'>{t('Suche')}</h2>
              <SearchForm
                searchKeywords={data?.quicksearch_keywords}
                faqText={data?.faq_text}
                faqPage={data?.faq_page}
              />
            </Tabs.Content>
            <Tabs.Content value='accessibility' className='navigation-tabs__content' forceMount>
              <h2 className='navigation__title'>{t('Barrierefreiheit')}</h2>
              <AccessibilityNavigation
                easyLanguageLink={removeBaseUrl(data?.easy_language_page?.full_url ?? '/')}
                accessibilityPageLink={removeBaseUrl(data?.accessibility_page?.full_url ?? '/')}
              />
            </Tabs.Content>
            <Tabs.Content value='shop' className='navigation-tabs__content' forceMount>
              <h2 className='navigation__title'>{t('Online Shop')}</h2>
              <TicketShopNavigation />
            </Tabs.Content>
          </Tabs.Root>
        </section>
        <div className='sub-navigation__container'>
          {data?.site_navbar?.map(nav => (
            <SubNavigation key={nav?.navbar?.id} data={nav?.navbar} />
          ))}
        </div>
      </div>
    </>
  );
}

export default Navigation;
