/* eslint-disable react/no-danger */
import React, { useId } from 'react';
import SearchIcon from '@icons/search.svg';
import { removeBaseUrl } from '@utils/url';
import Link from 'next/link';
import { QuicksearchKeyword, WagtailPage } from '@/types';
import { useTranslations } from 'next-intl';

type Props = {
  faqText?: string | null;
  faqPage?: WagtailPage | null;
  searchKeywords?: QuicksearchKeyword[];
};

function SearchForm(props: Props) {
  const { faqText, faqPage, searchKeywords } = props;
  const id = useId();
  const t = useTranslations('Navigation');

  return (
    <div className='search-form'>
      <form className='search-form__form' action='/de/suche' method='GET'>
        <input
          type='search'
          aria-label='Suchbegriff eingeben'
          name='search'
          id={`${id}-search`}
          className='search-form__input'
          placeholder='Suche...'
        />
        <button className='search-form__submit' type='submit' aria-label='Suche abschicken'>
          <SearchIcon />
        </button>
      </form>
      {searchKeywords?.length ? (
        <div className='search-form__quicksearch-keywords'>
          {searchKeywords.map(keyword => (
            <Link
              key={keyword.page.id}
              href={removeBaseUrl(keyword.page.full_url)}
              className='search-form__quicksearch'
            >
              {keyword.title}
            </Link>
          ))}
        </div>
      ) : null}
      {faqPage ? (
        <div className='search-form__faqs'>
          {faqText ? <div dangerouslySetInnerHTML={{ __html: faqText }} /> : null}
          {faqPage ? (
            <Link href={removeBaseUrl(faqPage?.full_url)} className='search-form__faq-link'>
              {t('Zu den FAQs')}
            </Link>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default SearchForm;
